@charset "utf-8";

/*X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 576px) {
    .content {
        padding: 0;
    }

    .navbar .form-control {
        max-width: 150px !important;
    }

    .row {
        --bs-gutter-x: 0;
    }

    .col {
        flex: 0 1 auto;
    }

    .react-bootstrap-table-pagination-list .pagination {
        justify-content: center;
    }

    .d-576-flex-even {
        justify-content: space-evenly;
    }

    .btn-576-float-none, .react-bootstrap-table-page-btns-ul {
        float: none !important;
    }

    .react-bootstrap-table-pagination-list, .added-tiny-margin-top, .added-tiny-margin-top2 {
        margin-top: 1rem;
    }

    .remove-margin-top-mob {
        margin-top: 0 !important;
    }

    .custom-576-100w {
        width: 100%;
    }

    h1, h2, h3 {
        text-align: center;
        margin-top: 1rem !important;
    }

    .h3 {
        font-size: 2em;
    }

    .textCenter576 {
        text-align: center;
    }

    .weekstatenTable {
        overflow-x: scroll;
    }

    .sm0ll-mobile-fix {
        min-width: 76px;
    }

    .card-header .d-flex {
        display: initial !important;
    }

    .search-label {
        width: 100%;
        margin-bottom: 1rem;
    }

    .d-20-left, .d-20-right {
        width: 20%;
    }

    .d-80-left, .d-80-right {
        width: 80%;
    }

    .d-10-left {
        width: 10%;
    }

    .d-70-mid {
        width: 70%;
    }

    .d-float-right {
        float: right;
    }

    .d-576-inline {
        display: inline-block;
        width: calc(46% - 25px);
    }

    .d-576-inline-img {
        display: inline-block;
        width: 40px;
    }

    .d-576-flex {
        display: flex;
        width: 100%;
    }

    .d-576-inline-start {
        display: inline-block;
        width: 70px;
    }

    .added-tiny-margin {
        margin: 5px;
    }

    .added-custom-button-margin576 {
        margin: 2rem 1rem 0 0;
    }

    .hideOn576 {
        display: none;
    }

    .showOn576 {
        display: inline-block !important;
    }

    .d-block-576 {
        display: block !important;
    }

    .d-to-back {
        z-index: -1;
    }

    .d-to-front {
        z-index: 50;
        position: relative;
    }

    .modal-75w {
        width: 96% !important;
    }

    .d-50-width {
        width: 50px;
    }

    .marginResetOn576, .rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
        margin: 0 !important;
    }

    .extremeSmallBtn {
        width: 40px;
        margin: 2px !important;
    }

    #mapping_table > tbody > tr > td {
        width: 150px;
    }

    .alert {
        margin-bottom: 0;
    }

    .rc-steps-item-icon {
        width: 52px !important;
    }

    .rc-steps-item-icon, .rc-steps-item-content {
        display: block;
    }

    .rc-steps-item, .rc-steps-item:last-child {
        flex: 0 1 auto !important;
        overflow: hidden !important;
    }

    .rc-steps-item-title:after {
        height: 0 !important;
    }
}

/* Richting tablet */
/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 1200px) {
    .added-tablet-m-right {
        margin-right: 1rem;
    }

    .remove-margin-top-mob {
        margin-top: 0 !important;
    }

    .content {
        padding: 0;
    }

    .row {
        --bs-gutter-x: 0;
    }

    .col {
        flex: 0 1 auto;
    }

    /*.react-bootstrap-table-pagination-list .pagination {*/
    /*    justify-content: center;*/
    /*}*/
    .d-576-flex-even {
        justify-content: space-evenly;
    }

    .btn-576-float-none, .react-bootstrap-table-page-btns-ul {
        float: none !important;
    }

    /*.react-bootstrap-table-pagination-list,*/
    .added-tiny-margin-top {
        margin-top: 1rem;
    }

    h1, h2, h3 {
        text-align: center;
        margin-top: 1rem !important;
    }

    .h3 {
        font-size: 2em;
        margin-left: 1rem;
    }

    .textCenter576 {
        text-align: center;
    }

    .weekstatenTable {
        overflow-x: scroll;
    }

    .sm0ll-mobile-fix {
        min-width: 76px;
    }

    .card-header .d-flex {
        display: initial !important;
    }

    .search-label {
        width: 100%;
        margin-bottom: 1rem;
    }

    .d-20-left {
        width: 20%;
    }

    .d-80-left {
        width: 80%;
    }

    .d-20-right {
        width: 20%;
    }

    .d-10-left {
        width: 10%;
    }

    .d-70-mid {
        width: 70%;
    }

    .d-80-right {
        width: 80%;
    }

    .d-float-right {
        float: right;
    }

    .d-576-inline {
        display: inline-block;
        width: calc(46% - 25px);
    }

    .d-576-inline-img {
        display: inline-block;
        width: 40px;
    }

    .d-576-flex {
        display: flex;
        width: 100%;
    }

    .d-576-inline-start {
        display: inline-block;
        width: 70px;
    }

    .fs-25px-h {
        height: auto !important;
    }

    .added-tiny-margin {
        margin: 5px;
    }

    .added-custom-button-margin576 {
        margin: 2rem 1rem 0 0;
    }

    div > .noShow576Plus {
        display: none !important;
    }

    .showOn576 {
        display: inline-block !important;
    }

    .d-block-576 {
        display: block !important;
    }

    .d-to-back {
        z-index: -1;
    }

    .d-to-front {
        z-index: 50;
        position: relative;
    }

    .modal-75w {
        width: 96% !important;
    }

    .marginResetOn576, .rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
        margin: 0 !important;
    }

    #mapping_table > tbody > tr > td {
        width: 150px;
    }

    .alert {
        margin-bottom: 0;
    }

    .rc-steps-item-icon {
        width: 52px !important;
    }

    .rc-steps-item-icon, .rc-steps-item-content {
        display: block;
    }

    .rc-steps-item, .rc-steps-item:last-child {
        flex: 0 1 auto !important;
        overflow: hidden !important;
    }

    .rc-steps-item-title:after {
        height: 0 !important;
    }

    .modal-dialog {
        max-width: 800px !important;
    }
}

/*Medium devices (tablets, 768px and up)*/
/*@media (min-width: 768px) {*/

/*}*/

/*Large devices (desktops, 992px and up)*/
/*@media (min-width: 992px) {*/

/*}*/

/*X-Large devices (large desktops, 1200px and up)*/
/*@media (min-width: 1200px) {*/

/*}*/

/*XX-Large devices (larger desktops, 1400px and up)*/
/*@media (min-width: 1400px) {*/

/*}*/

/* vanaf hier begint de custom css */

.comboh2 {
    position: relative;
    margin-top: -2.25em;
}

.greenCircle{
    border-radius: 100%;
    height: 2em;
    width: 2em;
    background-color: #2ecc71;
}

.yellowCircle{
    border-radius: 100%;
    height: 2em;
    width: 2em;
    background-color: #f1c40f;
}

.redCircle{
    border-radius: 100%;
    height: 2em;
    width: 2em;
    background-color: #e74c3c;
}

.weekstatenTableHeader h4 {
    cursor: pointer;
}

.weekstatenTableHeader h4:hover {
    color: #8bc34b;
}

details > summary {
    list-style: none;
}

details > summary::-webkit-details-marker, .hidden {
    display: none;
}

.react-bootstrap-table {
    overflow-x: auto !important;
}

.modalLinkStyling {
    cursor: pointer;
    color: #3f80ea;
}

.nav-tabs {
    margin-bottom: 0px;
}

/* Dropdown Button */
.dropbtn {
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {

}

/* optionele tabel styles  */
.bg-green-tablecreate {
    background-color: green !important;
}

.bg-blue-tablecreate {
    background-color: blue !important;
}

.bg-orange-tablecreate {
    background-color: orange !important;
}

/*.form-check-input{*/
/*    width: 50px!important;*/
/*    height: 20px!important;*/
/*    margin: 5px;*/
/*}*/

.bg-noB-r {
    border-right: 0px !important;
}

.bg-noB-l {
    border-left: 0px !important;
}

.big-checkbox {
    width: 20px !important;
    height: 20px !important;
    margin: 5px;
}

/* Optionele tabelstyles end */

.fs-25px-h {
    height: 25px;
}

.modal-75w {
    width: 75%;
    max-width: none !important;
}

.cAovjq {
    overflow-x: inherit !important;
}

.ant-picker-dropdown {
    z-index: 100000 !important;
}

.sidebar-brand {
    padding: 1.15rem 1.5rem 1.15rem 1.5rem;
    text-align: left;
}

.sidebar-brand img {
    width: 150px;
}

.makeSticky {
    position: sticky;
    top: 0;
}

.settings-toggle {
    height: auto;
}

.makeStyleRed {
    color: #e74c3c !important;
}

div.pac-container {
    z-index: 99999 !important;
}

#mapping_table tbody {
    display: block;
    height: 50vh;
    overflow-y: overlay;
}

#mapping_table thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}

#mapping_table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

#mapping_table tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
}

#mapping_table tbody::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(0, 0, 0, 0);
}

#mapping_table tbody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cccccc;
}

/*extra width table*/
#mapping_table_extra_width tbody {
    height: 50vh;
    overflow-y: overlay;
    width: 150%;
}

#mapping_table_extra_width thead {
    display: table;
    width: 150%;
    table-layout: fixed;
}

#mapping_table_extra_width tbody tr {
    display: table;
    width: 150%;
    table-layout: fixed;
}

#mapping_table_extra_width tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: #ffffff;
}

#mapping_table_extra_width tbody::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(0, 0, 0, 0);
}

#mapping_table_extra_width tbody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #cccccc;
}

.make-underlined:hover {
    text-decoration: underline;
}

.numInputWrapper span {
    width: 25px !important;
    padding: 10px 4px 0 2px !important;
    padding-left: 8px !important;
}

.removeListStyle {
    list-style-type: none;
}

.addButtonToInput {
    height: calc(2.24375rem + 2px);
    /*background: #f3f6fb;*/
    box-shadow: none;
    border: 0;
    padding: 0.35rem 0.75rem;
}

/* Tooltip container */
.uitleg {
    margin: 0 10px;
    position: relative;
    display: inline-block;
}

.uitleg:hover {
    cursor: pointer;
}

.uitlegtext {
    width: 350px;
    height: auto;
    margin-top: 10px;
    position: absolute;
    z-index: 50;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(153, 153, 153, 0.35);
    padding: 1em;
    border: 1px solid #eee;
    display: block;
}

.overlay-loading {
    background-color: rgba(1, 1, 1, 0.7);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000;
}